<template>
  <div class="siffaPageBottom">
    <div class="content_area ">
      <!-- <p class="friendLink">友情链接：</p> -->
      <!-- <ul class="link_box">
        <li
          v-for="(item, i) in Links_arr"
          :key="i"
          @click="link_click(item)"
          class="clearfix"
        >
          <div class="fl" :title="item.lable" @click="toHref(item)">
            <el-image :src="item.url" fit="contain"></el-image>
          </div>
        </li>
      </ul> -->
      <div class="contact_follow clearfix">
        <div class="contact">
          <h2 >联系我们</h2>
          <h3 style="color:#fff">For any question or enquire</h3>
          <ul>
            <li v-for="(item, i) in contact_arr" :key="i">
              {{ item.lable }}{{ item.content }}
            </li>
          </ul>
           <!--<ul class="concatTel">
            <li v-for="(item, i) in tel" :key="i">
              {{ item.lable }}{{ item.content }}
            </li>
          </ul>-->
        </div>
        
        <div class="follow">
          <h2>扫码，关注微信公众号</h2>
          <ul>
            <li v-for="(item, i) in QRcode_arr" :key="i">
             
              <el-image :src="item.url" fit="contain"></el-image>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="bottom tc">
      <bottomContent></bottomContent>
      <!-- 版权信息由AiLa提供技术支持服务 -->
    <!--<a href="https://ailaworld.com.cn/">AiLaworld.com</a>-->
    </div>
  </div>
</template>

<script>
import bottomContent from "~gbk/components/layouts/bottomContent";
export default {
  components: { bottomContent },
  data() {
    return {
      Links_arr: [
        {
          lable: "上海海关",
          url:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/href/href.png",
          href: "http://shanghai.customs.gov.cn/",
        },
        {
          lable: "科越信息",
          url:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/href/href1.png",
          href: "http://www.etongguan.com/",
        },
        {
          lable: "上海出入境检验检疫局",
          url:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/href/href2.png",
          href: "http://www.shciq.gov.cn/",
        },
        {
          lable: "中国上海",
          url:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/href/href3.png",
          href: "http://www.shanghai.gov.cn/",
        },
        {
          lable: "上海市商务委员会",
          url:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/href/href4.png",
          href: "https://sww.sh.gov.cn/",
        },
        {
          lable: "海关总署",
          url:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/href/href5.png",
          href: "http://www.customs.gov.cn/",
        },
        {
          lable: "AiLa",
          url:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/href/href6.png",
          href: "https://www.ailaworld.com.cn",
        },
      ],
      contact_arr: [
        {
          lable: "电话：",
          content: "+86-21-62886580",
        },
        {
          lable: "E-mail：",
          content: "gbk-china@gbk-ingelheim.cn",
        },
      ],
      tel:[
         {
          lable: "电话：",
          content: "15900703365",
        },
      
      ],
      QRcode_arr: [
        {
          name: "微信",
          url:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/gbk/gbkQecode.png",
        },
        // {
        //   name: "二维码",
        //   url:
        //     "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffaApplent/idCode.jpg",
        // },
      ],
    };
  },
  methods: {
    link_click(row) {},
    toHref(item) {
      window.open(item.href);
    },
  },
};
</script>

<style  scoped lang="less">
.siffaPageBottom {
  width: 100%;
  background: #345292;
  .friendLink {
    padding: 15px 0px 10px;
    font-size: 16px;
    color: #fff;
  }
  .link_box {
    display: flex;
    li {
      cursor: pointer;
      margin-right: 24px;
      // width: 64px;
      height: 24px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #fff;
      line-height: 22px;
    }
  }
  .concatTel{
    display: flex;
    flex-wrap: wrap;
    width: 500px;
    li{
      width: 250px;
      text-align: left;
    }
  }
  .contact_follow {
    padding-top: 42px;
    display: flex;
    h2 {
      font-size: 16px;
      font-family: PingFang-SC-Heavy, PingFang-SC;
      font-weight: 800;
      color: #DCDFE6;
      line-height: 22px;
    }
    .contact {
      padding-bottom: 26px;
      width: 1070px;
      // height: 220px;
      ul {
        padding-top: 26px;
        li {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #DCDFE6;
          line-height: 30px;
        }
      }
    }
    .follow {
      min-width: 200px;
      ul {
        display: flex;
        padding-top: 20px;
        li {
          margin-right: 20px;
          width: 130px;
          height: 130px;
          img {
            width: 130px;
            height: 130px;
          }
        }
      }
    }
  }
  .bottom {
    border-top: 1px solid #27488d;
    color: #5874b0;
    padding-bottom: 10px;
    font-size: 12px;
    a {
      color: #fff;
      font-size: 12px;
    }
  }
}
</style>
