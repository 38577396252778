<template>
  <div class="siffaDefaultLayout">
    <navMenu></navMenu>
    <backPageTop></backPageTop>
    <router-view />
    <pageBottom class="pageBottom"></pageBottom>
  </div>
</template>

<script>
import navMenu from "~gbk/components/layouts/navMenu";
import pageBottom from "~gbk/components/layouts/pageBottom";
import backPageTop from "~/baseComponents/backPageTop";
export default {
  components: { navMenu, pageBottom, backPageTop },
  mounted() {},
};
</script>

<style scoped lang="less">
.siffaDefaultLayout {
  min-height: 100vh;
  background: #fafafa;
  position: relative;
  padding-bottom:241px;
  .pageBottom{
    position: absolute;
    bottom: 0;
  }
}
</style>