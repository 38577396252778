//阿里云日志服务
import Vue from 'vue'
import { getOs, getBrowser } from '~/baseUtils/getLogInfo'
import store from './store'
import router from './router'

let logger;
if (process.env.VUE_APP_API_ENV!='idc') {
    logger = new window.Tracker('cn-beijing.log.aliyuncs.com', 'aila-2', 'aila-test');
} else {
    logger = new window.Tracker('cn-beijing.log.aliyuncs.com', 'aila-2', 'aila-world');
}
let os = getOs();
let agent_type = 'gbkSociety web';
let browser = getBrowser().browserName;
let browser_ver = getBrowser().browserVer;
let fullRouter = null;

let $_log = (params) => {
    logger.push('time', moment().unix());
    logger.push('pre_module_id', '');
    logger.push('module_id', router.currentRoute.path || '');
    logger.push('agent_type', agent_type);
    logger.push('action_type', params.type || 1);  //1:点击操作类，2.进入页面类
    logger.push('action_id', params.id);
    logger.push('user_id', store.state.baseStore.userId || '');
    logger.push('user_type', store.state.baseStore.userId != '' ? 1 : 3)  //1.前台会员，2.后台管理人员，3.临时人员
    logger.push('action_content', params.content || '');
    logger.push('company_id', store.state.baseStore.userInfo.companyId || '');
    logger.push('os', os);
    logger.push('topic', params.topic);
    logger.push('browser', browser);
    logger.push('browser_ver', browser_ver);
    logger.push('language', store.state.baseStore.locale);
    logger.push('version', 'gbkSociety');
    logger.push('resolution', `${window.screen.width}*${window.screen.height}`)
    logger.logger()
}
export default $_log;

