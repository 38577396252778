<template>
  <div class="sign_layout">
    <iframe
      src="https://canvas.aila.site/"
      frameborder="0"
      width="100%"
      height="100%"
    ></iframe>
    <div class="router_view_content">
      <router-view></router-view>
    </div>
    <div class="text_info tc">
      <div class="bottom tc">
        <!-- <bottomContent></bottomContent> -->
        Copyright@2021 mpa 版权所有 技术支持由
        <a href="https://ailaworld.com.cn/" >链韵物流科技（上海）有限公司提供</a>
    </div>

    </div>
    
  </div>
</template>

<script>
import bottomContent from "~gbk/components/layouts/bottomContent";
export default {
  components:{bottomContent}
};
</script>

<style scoped lang="less">
.sign_layout {
  width: 100%;
  height: 100vh;
  position: relative;
  .router_view_content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .text_info {
    width: 100%;
    position: absolute;
    bottom: 10px;
    font-size: 12px;
    p {
      color: #909399;
      margin-bottom: 15px;
    }
  }
  .bottom {
    color: #666;
    padding-bottom: 10px;
    font-size: 12px;
  }
}

</style>