import Vue from 'vue'
import {baseConsoleRoutes} from '~bac/router'

Vue.use(VueRouter)

export default new VueRouter({
  mode: 'history',
  routes: [{
    path: '/test',                                              //首页
    name: 'test',
    component: resolve => require(['~gbk/views/test'], resolve),
  },
    {
      path: '/',                                              //首页
      name: 'index',
      component: resolve => require(['~gbk/views/index'], resolve),

    },
    {
      path: '/downloadSection',                                              //首页
      name: 'downloadSection',
      component: resolve => require(['~gbk/views/index/downloadSection/index'], resolve),
    },
    {
      path: '/activitys',                                              //首页--活动
      name: 'activitys',
      component: resolve => require(['~gbk/views/index/activitys/index'], resolve),
      children: []
    },
    // {
    //   path: '/memberShip',                                              //申请入会
    //   name: 'memberShip',
    //   component: resolve => require(['~gbk/views/index/memberShip/index'], resolve),
    //   children: [
    //     {
    //       path: '/',
    //       name: 'applyFor',
    //       component: resolve => require(['~gbk/views/index/memberShip/flow'], resolve)
    //     }, {
    //       path: '/memberShip/applyFor',
    //       name: 'applyFor',
    //       component: resolve => require(['~gbk/views/index/memberShip/applyFor'], resolve)
    //     }]
    // },
    {
      path: '/associationList',                                  //协会联盟介绍
      name: 'associationList',
      component: resolve => require(['~gbk/views/association/associationList'], resolve)
    },
    {
      path: '/associationDetail',                                  //协会联盟详情
      name: 'associationDetail',
      component: resolve => require(['~gbk/views/association/associationDetail'], resolve)
    },
    {
      path: '/activity',                                  //培训活动
      name: 'activity',
      component: resolve => require(['~gbk/views/activity/index'], resolve)
    },
    {
      path: '/college',                                  //物贸学院
      name: 'college',
      component: resolve => require(['~gbk/views/college/index'], resolve)
    },
    {
      path: '/college_detail',                                  //物贸学院 详情
      name: 'college_detail',
      component: resolve => require(['~gbk/views/college/detail'], resolve)
    },
    {
      path: '/positionList',                                  //人才中心
      name: 'positionList',
      component: resolve => require(['~gbk/views/position/list'], resolve)
    },
    {
      path: '/positionDetail',                                //职位详情
      name: 'positionDetail',
      component: resolve => require(['~gbk/views/position/detail'], resolve)
    },
    {
      path: '/survey',                                              //协会概况
      name: 'survey',
      component: resolve => require(['~gbk/views/survey'], resolve),
      children: [{
        path: '/',
        name: 'brief',
        component: resolve => require(['~gbk/views/survey/brief'], resolve)
      }, {
        path: '/survey/director',
        name: 'director',
        component: resolve => require(['~gbk/views/survey/director'], resolve)
      }, {
        path: '/survey/framework',
        name: 'framework',
        component: resolve => require(['~gbk/views/survey/framework'], resolve)
      }, {
        path: '/survey/leader',
        name: 'leader',
        component: resolve => require(['~gbk/views/survey/leader'], resolve)
      }, {
        path: '/survey/supervisor',
        name: 'supervisor',
        component: resolve => require(['~gbk/views/survey/supervisor'], resolve)
      }, {
        path: '/survey/member',
        name: 'member',
        component: resolve => require(['~gbk/views/survey/member'], resolve)
      }, {
        path: '/survey/constitution',
        name: 'constitution',
        component: resolve => require(['~gbk/views/survey/constitution'], resolve)
      }, {
        path: "/survey/memorabilia",
        name: 'memorabilia',
        component: resolve => require(['~gbk/views/survey/memorabilia'], resolve)
      }]
    }, {
      path: '/companyCatalog',//会员名录
      name: 'companyCatalog',
      component: resolve => require(['~gbk/views/company/companyCatalog'], resolve)
    }, {
      path: '/companyContactList',
      name: 'companyContactList',
      component: resolve => require(['~gbk/views/company/companyContactList'], resolve)
    }, {
      path: '/companyDetail',
      name: 'companyDetail',
      component: resolve => require(['~gbk/views/company/companyDetail'], resolve)
    }, {
      path: '/sign',
      name: 'sign',
      component: resolve => require(['~gbk/views/users/sign'], resolve),
      meta: {
        layout: 'sign'
      }
    },
    {
      path: '/forgetPassword',
      name: 'forgetPassword',
      component: resolve => require(['~gbk/views/users/forgetPassword'], resolve),
    },
    {
      path: '/resetPassword',
      name: 'resetPassword',
      component: resolve => require(['~gbk/views/users/resetPassword'], resolve),
    },
    // {
    //   path: '/signUp',
    //   name: 'signUp',
    //   component: resolve => require(['~gbk/views/users/signUp'], resolve),
    //   meta: {
    //     layout: 'sign'
    //   }
    // }, 
    {
      path: '/activeAccount',
      name: 'activeAccount',
      component: resolve => require(['~gbk/views/users/activeAccount'], resolve),
    }, {
      path: '/memberCenter',
      name: 'memberCenter',
      component: resolve => require(['~gbk/views/memberCenter'], resolve)
    },
    {
      path: "/creditAssessment",//信用评估页面
      name: "creditAssessment",
      component: resolve => require(['~gbk/views/creditAssessment'], resolve)
    },
    {
      path: "/creditDetail",//信用评估详情页面
      name: "creditDetail",
      component: resolve => require(['~gbk/views/creditAssessment/detail'], resolve)
    },
    {
      path: "/basicInfo",//完善信息
      name: "basicInfo",
      component: resolve => require(['~gbk/views/users/basicInfo'], resolve)
    },
    {
      path: "/contactUs",//完善信息
      name: "contactUs",
      component: resolve => require(['~gbk/views/contactUs/index'], resolve)
    },
    {
      path: "/detail",//首页活动详情
      name: "detail",
      component: resolve => require(['~gbk/views/index/detail'], resolve)
    },
    {
      path: "/newsDetail",//完善信息
      name: "detail",
      component: resolve => require(['~gbk/views/index/detail/newsDetail'], resolve)
    },
    {
      path: "/memberInfo",
      name: "memberInfo",
      component: resolve => require(['~gbk/views/index/detail/companyInfo'], resolve)
    },
    {    // 风险
      path: "/warningList",
      name: "warningList",
      component: resolve => require(['~gbk/views/warningList/index'], resolve)
    },
    {
      path: '/handleInitialParams',
      name: 'handleInitialParams',
      meta: {
        layout: 'empty'
      },
      component: resolve => require(['~gbk/views/users/initPage'], resolve)
    },
    {
      path: "/overseas",//直通海外
      name: "overseas",
      component: resolve => require(['@/baseComponents/baseInquiry/overseas/index'], resolve)
    },
    {
      path: "/publishInquiry",//一键发盘
      name: "publishInquiry",
      component: resolve => require(['@/baseComponents/baseInquiry/publishInquiry/index'], resolve)
    },
    {
      path:'/dgsa',//dgsa
      name:'dgsa',
      component:resolve => require(['~gbk/views/dgsa'],resolve),  
    },
    {
      path:'/dgsaList',
      name:'dgsaList',
      component:resolve => require(['~gbk/views/dgsa/dgsaList'],resolve),
      
    },
    {
      path: '/SDSFileUpload',
      name: 'SDSFileUpload',
      component:resolve => require(['~gbk/views/dgsa/SDSFileUpload'],resolve),
    },
    {
      path:'/certificateQuery',//证书查询
      name:'certificateQuery',
      component:resolve => require(['~gbk/views/certificateQuery'],resolve)
    },
    {
      path: '/serverHome',                                  // 企业服务首页
      name: 'serverHome',
      component: resolve => require(['@/baseComponents/enterpriseServices/home'], resolve)
    },
    {
      path: '/serverDetail',                                  // 企业服务详情页
      name: 'serverDetail',
      component: resolve => require(['@/baseComponents/enterpriseServices/serverDetail'], resolve)
    },
  ].concat(baseConsoleRoutes).concat([{
    path: "**",
    redirect: "/"
  }]),
  scrollBehavior(to, from, savedPosition) {
    return {x: 0, y: 0}
  }
})
