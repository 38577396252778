<template>
  <div class="navMenu">
    <!-- <div class="navMenuLeft"></div>
    <div class="navMenuRight">
      <div class="rightTop"></div>
      <div class="rightBottom"></div>
    </div> -->
    <div class="navContent">
      <!-- <div class="content_area"> -->
      <!-- <div class="logo">
          <img :src="menuLogo" alt="" />
        </div> -->
      <div class="topHeader">
        <div class="content_area">
          <div class="fr clearfix topSearch">
            <div class="fl">
              <!-- <el-input size="mini" v-model="searchVal">
                  <i
                    slot="suffix"
                    class="el-icon-search cursor"
                    style="margin-right: 5px"
                    @click="searchContent"
                  ></i>
                </el-input> -->
              <!-- <el-autocomplete
                  size="mini"
                  v-model="searchVal"
                  :fetch-suggestions="querySearchCompanyName"
                  placeholder="请输入内容"
                  :trigger-on-focus="false"
                >
                  <i
                    slot="suffix"
                    class="el-icon-search cursor"
                    style="margin-right: 5px"
                    @click="searchContent"
                  ></i
                ></el-autocomplete> -->

              <!-- <searchCompany v-model="searchVal" @search="searchContent"></searchCompany> -->
            </div>
            <div class="fl routerLink">
              <span>欢迎您！ </span>
              <span v-if="!IS_LOGIN">
                <router-link to="/sign" class="goLogin">
                  <span>登录</span>
                </router-link>
                <!-- <router-link to="/signUp" class="goRegister">
                  <span>注册</span>
                </router-link> -->
              </span>
              <span v-else>
                <el-popover
                  placement="bottom-start"
                  trigger="click"
                  v-model="visible"
                >
                  <div class="navMenuselfAccountInfo">
                    <p class="textOverflow">
                      姓名：{{
                        USER_INFO.name_en
                          | priorFormat(USER_INFO.name_zh, LOCALE)
                      }}
                    </p>
                    <p class="textOverflow">
                      职位：{{
                        USER_INFO.job_title_en
                          | priorFormat(USER_INFO.job_title_zh, LOCALE)
                      }}
                    </p>
                    <p class="textOverflow">
                      邮箱：{{ USER_INFO.email | textFormat }}
                    </p>
                    <p class="textOverflow">
                      手机号：{{ USER_INFO.mobile | textFormat }}
                    </p>
                  </div>

                  <div class="navMenuselfAccountInfoBtn">
                    <el-button
                      type="danger"
                      size="small"
                      @click="
                        visible = false;
                        logOut();
                      "
                      >退出登录</el-button
                    >
                  </div>
                  <img
                    :src="USER_INFO.avatar || defaultAvatar"
                    alt
                    class="userAvatar cursor"
                    slot="reference"
                  />
                </el-popover>
                <router-link to="/console/account/userInfo" style="color:#fff"
                  >控制台</router-link
                >
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="navMenuLink">
        <div class="content_area clearfix">
          <div class="fl pageLogo">
            <img :src="menuLogo" alt="" />
          </div>
          <div class="fr meau">
            <el-menu
              class="el-menu-atf"
              mode="horizontal"
              :default-active="activeIndex"
              menu-trigger="click"
              @select="selectMenu"
            >
              <el-menu-item index="/">网站首页</el-menu-item>
              <!-- <el-submenu index="/3">
                <span slot="title" class="dgsa">
                  DGSA
                  <img v-if="hotShow" :src="hot" alt="" />
                </span>
                <el-menu-item index="/dgsa">
                  DGSA危险品安全顾问
                  <img
                    src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/gbk/HOT.png"
                    alt=""
                  />
                </el-menu-item>
              </el-submenu> -->
              <el-menu-item :index="this.IS_LOGIN ? '/dgsa' : '/sign'">DGSA</el-menu-item>
              <el-menu-item index="/certificateQuery">
                证书查询
              </el-menu-item>
              <!--<el-menu-item index="/survey">联盟概况</el-menu-item>-->
              <!-- <el-menu-item index="/companyCatalog">会员名录</el-menu-item> -->
              <el-submenu index="/2">
                <template slot="title">联盟成员</template>
                <el-menu-item index="/companyCatalog">企业名录</el-menu-item>
                <el-menu-item index="/companyContactList"
                  >联系人列表</el-menu-item
                >
              </el-submenu>
              <el-menu-item index="/overseas">直通海外</el-menu-item>
              <el-menu-item index="/activity">活动</el-menu-item>
              <el-menu-item index="/college">培训学院</el-menu-item>

              <el-menu-item index="/positionList">校企合作</el-menu-item>
              <el-submenu index="/6">
                <template slot="title">新闻资讯</template>
                <el-menu-item index="/activitys?content_type=3"
                  >行业动态</el-menu-item
                >
                <el-menu-item index="/activitys?content_type=4"
                  >政策法规</el-menu-item
                >
              </el-submenu>
              <el-menu-item index="/contactUs">平台客服热线</el-menu-item>
            </el-menu>
          </div>
        </div>
      </div>
      <!-- </div> -->
    </div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="300px"
      :close-on-click-modal="true"
    >
      <div class="tc openDialog">暂未开放</div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span> -->
    </el-dialog>
  </div>
</template>

<script>
import searchCompany from "~gbk/components/company/unit/searchCompany";

export default {
  components: { searchCompany },
  data() {
    return {
      visible: false,
      defaultAvatar:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/headportrait.png",
      activeIndex: "/",
      menuLogo:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/gbk/pcLogo.png",
      searchVal: "",
      zhReg: /^[\u4e00-\u9fa5]+$/i,
      dialogVisible: false,
      hot:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/gbk/navHot.png",
    };
  },
  methods: {
    logOut() {
      this.$store.commit("baseStore/CLEAR_USER_INFO");
      this.$router.push("/sign");
    },
    // 搜索框
    searchContent() {
      if (this.searchVal) {
        this.$router.push({
          path: "/companyCatalog",
          query: {
            parameter: this._encode({
              searchVal: this.searchVal,
            }),
          },
        });
      } else {
        this.$router.push("/companyCatalog");
      }
    },
    async querySearchCompanyName(queryString, cb) {
      // if (queryString.length < 3) {
      //   cb([]);
      //   return;
      // }
      let params = {};
      params.company_name = queryString;
      // params.certificate = 20;
      params.source = this.PJSource;
      params.usage = 2;
      params.is_vip = 1;
      // params.genre_ids = this.genreType;
      let res = await this.$store.dispatch(
        "baseStore/company_associateSearch",
        params
      );
      let values;
      if (this.zhReg.test(queryString)) {
        values = res.data.map((item) => {
          item.value = item.name_zh;
          return item;
        });
      } else {
        // 输入是英文或者是数字
        values = res.data.map((item) => {
          if (item.name_en) {
            // 返回数据中，英文有值
            item.value = item.name_en;
          } else {
            item.value = item.name_zh;
          }
          return item;
        });
      }
      cb(values);
    },
    handleActive(path) {
      if (path.indexOf("survey") != -1) {
        this.activeIndex = path.substring(0, 7);
      } else {
        this.activeIndex = path;
      }
    },
    selectMenu(index, path) {
      if (index == "") {
        this.dialogVisible = true;
      }
      // if(index == "/dgsa") {
      //   if (!this.IS_LOGIN) {
      //     return this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN");
      //   }
      // }
      
      let auth = this.$store.state.baseStore.auth.USER_AUTH.menu
      // 判断用户是否有权限
      if(index == "/dgsa") {
        let is_exist = auth.find((item)=>{
          return item.identifier == "dga:dgs"
        })
        if(!is_exist) return this.$message.error("您好，您当前尚未开通DGSA模块权限，如需开通请联系+86-21-62886580或者gbk-china@gbk-ingelheim.cn")
      }
      this.$router.push(index)
    },
  },
  watch: {
    $route(nv) {
      this.handleActive(nv.path);
      // if (this.$route.query.parameter) {
      //   this.searchVal = this._decode(nv.query.parameter).searchVal;
      // } else {
      //   this.searchVal = "";
      // }
    },
  },
  computed: {
    hotShow() {
      //开始计时时间
      let time = moment("2022-03-07").valueOf();
      //七天后时间
      let sevenDay = moment(time)
        .add(7, "day")
        .valueOf();
      return sevenDay - moment().valueOf() > 0 ? true : false;
    },
  },
  mounted() {},
};
</script>

<style scoped lang="less">
.navMenu {
  width: 100%;
  .userAvatar {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 15px;
  }
  height: 100px;
  width: 100%;
  position: relative;
  .navContent {
    width: 100%;
    height: 100px;
    position: absolute;
    top: 0px;
    left: 0px;
    .content_area {
      position: relative;
      .logo {
        position: absolute;
        top: 0px;
        left: 0px;
      }
    }
    .topSearch {
      line-height: 40px;
      .routerLink {
        margin-left: 32px;
        color: #eceef5;
      }
      .iconSearch {
        margin-right: 5px;
      }
    }
  }
  .topHeader {
    height: 40px;
    background: #345292;
    overflow: hidden;
  }
  .navMenuLink {
    height: 60px;
    background: #fff;
    .pageLogo {
      margin-top: 5px;
    }
  }
}
.userImg {
  margin-right: 10px;
  img {
    width: 26px;
    height: 26px;
    border-radius: 50%;
  }
}
.goLogin {
  color: #fff;
}
.goRegister {
  display: block;
  color: #385998;
  float: right;
  background: #7ec9f1;
  width: 76px;
  text-align: center;
  margin-left: 15px;
}
/deep/.el-input--mini .el-input__inner {
  border-radius: 15px;
}
.openDialog {
  height: 100px;
  line-height: 100px;
}
/**
导航条的样式
*/
.el-menu-atf {
  border-bottom: none;
}
.el-menu {
  background: transparent;
}
.submenu {
  width: 110px;
}
/deep/ .el-submenu__icon-arrow {
  color: #999 !important;
}
// /deep/.el-menu-item {
//   background-color: transparent !important;
//   color: #fea2a4 !important;
// }
// /deep/.el-menu-item:hover {
//   outline: 0 !important;
//   color: #fff !important;
// }
// /deep/.el-menu-item.is-active {
//   color: #fff !important;
//   background: transparent !important;
// }
// /deep/.el-menu--horizontal > .el-submenu .el-submenu__title,
// .el-menu--horizontal > .el-menu-item {
//   border-bottom: none;
//   &.is-active {
//     color: #fff;
//     border-bottom: 0;
//     background: transparent;
//   }
// }
// /deep/.el-menu--horizontal > .el-submenu {
//   .el-submenu__title {
//     color: #fea2a4 !important;
//     border-bottom: none !important;
//     i {
//       color: #fea2a4;
//     }
//     &:hover {
//       background: transparent;
//       color: #fff !important;
//     }
//   }
// }
// /deep/.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
//   color: #fff !important;
// }
// .el-menu--horizontal .el-menu .el-menu-item.is-active,
// .el-menu--horizontal .el-menu .el-submenu.is-active > .el-submenu__title {
//   background: #fef7f7 !important;
//   color: #f45b5b;
// }
/deep/.el-menu.el-menu--horizontal {
  border-bottom: none;
}
&.el-menu--horizontal > .el-menu-item {
  padding: 0 15px;
}
&.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 2px solid #7ec9f1;
}
</style>
<style lang="less">
.el-menu--collapse .el-menu .el-submenu,
.el-menu--popup {
  min-width: 110px !important;
  text-align: center;
}
.navMenuselfAccountInfo {
  p {
    line-height: 28px;
  }
  .navMenuselfAccountInfoBtn {
    margin-top: 8px;
  }
}
.dgsa {
  position: relative;
  img {
    position: absolute;
    width: 26px;
    height: 17px;
    right: -20px;
    top: -12px;
    z-index: 999;
  }
}
</style>
