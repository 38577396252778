import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
Vue.config.productionTip = false

import * as filters from './plugins/filters'
import './assets/style/elementStyle/index.css'
import '~/basePlugins/index'
import store from './store/index'
import { getI18n } from '~/basePlugins/i18n'
import './assets/style/base.less'
import { initUserIdAndInfo } from '~/baseUtils'
import '~bac/assets/style/baseConsole.less'
import mixin from './mixin'
import $_log  from './log'
Vue.prototype.$log = $_log;
Vue.mixin(mixin);
Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key])
});

import '~/basePlugins/indexBaseConsole'

(async () => {
    await initUserIdAndInfo(store, 'baseStore/baseSign_getUserInfo')
    new Vue({
        render: h => h(App),
        router,
        store,
        i18n: await getI18n()
    }).$mount('#app')
})()

